
import { extendTheme } from '@chakra-ui/react'
import { theme as baseTheme } from '@saas-ui/theme-glass'


export const theme = extendTheme(

    {
      useSystemColorMode: true,
    }
    
    )